//QrCodeScan Modal

.qrcontent .modal-content {
  padding: 10px;
  border-bottom: 10px;
}

.badgestatus {
  margin-left: 22px;
  margin-bottom: 8px;
  margin-top: 1px;
  font-size: 16px;
}

.qrbuttons, .qrbuttons *{
  &:not(button){
    padding: 0;
  }
  button{
    width: 95%;
    height: 95%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  margin: 0;
}

//QrCodeScan Componente

.componentimgqr {
  padding-right: 30px;
  padding-bottom: 30px;
}


.qrscan > div {
  padding-top: 0 !important;
  #video {
    position: relative !important;
  }
}
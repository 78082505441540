.react-select {
  width: 100%;
  height: 40px;
  font-size: 12px;
}

.react-select__control {
  height: 32px;
  border-radius: 0 !important;
  transition: all 0.3s;

  @include themify($themes) {
    border: 1px solid themed('colorFieldsBorder');
    background-color: themed('colorBackground');
  }

  &.react-select__control--is-focused, &:hover {
    border-color: $color-accent !important;
    box-shadow: none;
    background: transparent;
  }
}

.react-select__input {
  height: 30px;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator, .react-select__clear-indicator {
  cursor: pointer;

  @include themify($themes) {
    color: themed('colorIcon');
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.react-select__multi-value {
  background-color: transparent;
  border: 1px solid $color-blue;

  .react-select__multi-value__label {
    padding: 3px 6px;

    @include directify($directions) {
      #{directed('border-right')}: 1px solid $color-blue
    }

    @include themify($themes) {
      color: themed('colorText');
    }
  }
}

.react-select__multi-value__remove {
  opacity: 0.8;
  transition: 0.3s;
  cursor: pointer;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.react-select__multi-value__label, .react-select__multi-value__remove {

  @include themify($themes) {
    background: themed('colorBackground');
  }
}

.react-select__single-value {

  @include themify($themes) {
    color: themed('colorText');
  }
}

.react-select__menu {
  box-shadow: none !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

.react-select__menu-list {
  top: calc(100% + 1px);
  border-radius: 0;
  box-shadow: none;
  font-size: 12px;
  overflow: hidden;

  @include themify($themes) {
    background: themed('colorBackground');
    border: 1px solid themed('colorFieldsBorder');
  }
}

@keyframes open {
  0% {
    max-height: 0
  }
  100% {
    max-height: 200px
  }
}

.react-select__option {
  transition: all 0.3s;
  border-radius: 0;
  display: flex;
  cursor: pointer;
  padding: 8px 10px;

  @include themify($themes) {
    background: themed('colorBackground');
    color: themed('colorText');
  }

  &.react-select__option--is-focused {

    @include themify($themes) {
      background: themed('colorHover');
    }
  }
}

.react-select__color {
  display: block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: auto;
  margin-bottom: auto;

  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 5px;
  }
}

.form__select-color {
  display: flex;
  flex-wrap: wrap;
  max-width: 84px;

  @include directify($directions) {
    #{directed('margin-right')}: 40px;
  }
}

.react-select-usuario {
  .css-1okebmr-indicatorSeparator {
    width: 0;
  }

  .css-1s2u09g-control {
    max-height: 38px;
    border-radius: 0px;
    border: 1px solid #f2f4f7;
    align-items: center;

    .css-319lph-ValueContainer {
      padding: 0px 8px;
    }
  }
  

  .css-tlfecz-indicatorContainer {
    .css-tj5bde-Svg {
      height: 16px;
      width: 16px;
    }
  }

  .css-1gtu0rj-indicatorContainer {
    color: hsl(0, 0%, 80%);
    .css-tj5bde-Svg {
      height: 16px;
      width: 16px;
    }
  }

  .css-26l31y-menu {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: hidden;
  
    @include themify($themes) {
      background: themed('colorBackground');
      border: 1px solid themed('colorFieldsBorder');
    }
  }

  .css-1pahdxg-control:hover {
    border-color: #a50000;
    box-shadow: 0 0 0 0px #a50000;
    background: transparent;
  }

  .css-1pahdxg-control {
    border-color: #a50000;
    border-radius: 0px;
    box-shadow: 0 0 0 0px #a50000;
    max-height: 38px;

    .css-319lph-ValueContainer {
      padding: 0px 8px;
    }
  }

  .css-26l3qy-menu {
    border-radius: 0px;
    color: #646777;
  }

  .css-1n7v3ny-option {
    background: #fafbfe;
  }

}
